var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-custom"},[_c('ul',{staticClass:"list-unstyled topnav-menu float-right mb-0"},[_c('b-nav-item-dropdown',{staticClass:"notification-list text-white",attrs:{"right":""}},[_c('template',{staticClass:"nav-link dropdown-toggle",slot:"button-content"},[_c('span',{staticClass:"text-white"},[_c('i',{staticClass:"fas fa-home"})])]),_c('b-dropdown-text',{staticClass:"dropdown-item noti-title",attrs:{"href":"#"}},[_c('span',[_vm._v("Branch")])]),_vm._l((_vm.listUserBranch),function(item,key){return _c('b-dropdown-item',{key:key,staticClass:"dropdown-item active"},[(item.id_flexmod === null)?_c('span',[_c('a',{style:(parseInt(item.branch.id) === parseInt(_vm.defaultBranch)
							? 'color:#51C1AD;'
							: 'color:#000 '),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.changeBranch(item.branch.id)}}},[_vm._v(" "+_vm._s(item.branch.nama_branch)+" ")])]):_c('span',[_c('a',{style:(parseInt(item.is_default) === 1
							? 'color:#51C1AD;'
							: 'color:#000 '),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.changeBranch(item.branch.id)}}},[_vm._v(" "+_vm._s(item.branch.nama_branch)+" ")])])])})],2),_c('b-nav-item-dropdown',{staticClass:"notification-list",attrs:{"right":""}},[_c('template',{staticClass:"nav-link dropdown-toggle",slot:"button-content"},[_c('i',{staticClass:"mdi mdi-bell-off"}),(_vm.listUserNotif.length)?_c('div',{staticClass:"notify"},[_c('span',{staticClass:"heartbit"}),_vm._v(" "),_c('span',{staticClass:"point"})]):_vm._e()]),_c('b-dropdown-text',{staticClass:"dropdown-item noti-title",attrs:{"href":"#"}},[_c('span',[_vm._v("Notifications")])]),_c('b-dropdown-text',{staticClass:"p-0",attrs:{"href":"#"}},[_c('VuePerfectScrollbar',{staticClass:"noti-scroll",staticStyle:{"max-width":"300px","width":"300px"},attrs:{"suppress-scroll-x":false}},_vm._l((_vm.listUserNotif),function(item,key){return _c('div',{key:key,staticClass:"table-responsive"},[_c('a',{staticClass:"dropdown-item notify-item",attrs:{"href":item.url_notification}},[_c('div',{staticClass:"notify-icon mr-3"},[_c('img',{staticStyle:{"width":"45px","height":"45px","border-radius":"8.25rem !important"},attrs:{"src":item.logo_user
										? item.logo_user
										: 'https://ui-avatars.com/api/?name=' +
										item.created_name}})]),_c('p',{staticClass:"notify-details"},[_vm._v(" "+_vm._s(item.created_name)+" "),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(item.msg_notification)),_c('br'),_vm._v(" "+_vm._s(item.created_on)+" ")])])])])}),0),_c('a',{staticClass:"dropdown-item text-center text-oak notify-item notify-all",attrs:{"href":_vm.ciUrl + '/admin/notification_list'}},[_c('u',[_vm._v("Check all notification")]),_c('i',{staticClass:"fas fa-chevron-right ml-2"}),_c('i',{staticClass:"fi-arrow-right"})])],1)],2),_c('b-nav-item-dropdown',{staticClass:"notification-list",attrs:{"right":"","menu-class":"profile-dropdown"}},[_c('template',{slot:"button-content"},[_c('div',{staticClass:"nav-user mr-0"},[_c('span',{staticClass:"pro-user-name ml-1 mr-2"},[_vm._v(" Hi, "+_vm._s(_vm.userName ? _vm.userName : 'User')+" ")]),_c('img',{staticStyle:{"border-radius":"8.25rem !important"},attrs:{"src":_vm.logoUser
							? _vm.logoUser
							: 'https://ui-avatars.com/api/?name=' + _vm.userName}})])]),_c('b-dropdown-text',{staticClass:"dropdown-item noti-title"},[_c('span',[_vm._v("Profile")])]),_c('b-dropdown-item',{attrs:{"href":_vm.ciUrl + '/admin/profile'}},[_c('i',{staticClass:"fas fa-user"}),_c('span',[_vm._v("My Profile")])]),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-in-alt"}),_c('span',[_vm._v("Logout")])])],2)],1),_c('div',{staticClass:"logo-box"},[_c('router-link',{staticClass:"logo text-center",attrs:{"tag":"a","to":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"50px"},attrs:{"src":require("@assets/images/logo-oak.svg"),"alt":""}})]),_c('span',{staticClass:"logo-sm"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"50px"},attrs:{"src":require("@assets/images/logo-oak.svg"),"alt":""}})])])],1),_c('ul',{staticClass:"list-unstyled topnav-menu topnav-menu-left m-0"},[_c('li',[_c('button',{staticClass:"button-menu-mobile",on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fe-menu"})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }